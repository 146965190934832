import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  CoreModal,
  useToggle,
  planSearchFields,
  CoreDataListPage,
  useQueryString,
} from '@amzn/dots-core-ui';

import { PlanForm } from './PlanForm';
import { Plan, useClient } from '../../api';
import PlanColumnDefinitions from './PlanColumnDefinitions';

type PlanSearchParams = {
  name?: string;
  page?: number;
  pageSize?: number;
};
const PlanList = (): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isModalOpen, closeModal, openModal] = useToggle();
  const { tmsClient } = useClient();
  const { params } = useQueryString<PlanSearchParams>();

  const dataFetcher = async () => {
    const response = await tmsClient.plans.getAll({
      name: params.name,
      page: params.page || 1,
      pageSize: params.pageSize,
    });

    return {
      content: response.content,
      count: response.pageSize,
      total: response.pageTotal,
    };
  };

  return (
    <>
      <CoreModal
        title="Add a New Plan"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <PlanForm
          onCancel={closeModal}
          onSubmit={async (newPlan): Promise<void> => {
            const result = await tmsClient.plans.create(newPlan);
            history.push(`${path}/${result.id}/suites`);
          }}
        />
      </CoreModal>
      <CoreDataListPage
        title="Test Plans"
        columnDefinitions={PlanColumnDefinitions}
        getItemId={(item: Plan): number => item.id}
        filters={planSearchFields}
        dataFetcher={dataFetcher}
        onAddButtonClick={openModal}
        onRowClick={(item: Plan): void =>
          history.push(`${path}/${item.id}/suites`)
        }
      />
    </>
  );
};

export default PlanList;
