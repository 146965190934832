import { HttpClient, PaginatedResponse } from '@amzn/dots-core-ui';
import { Package } from '../models';

export interface PackageApi {
  getAll: (filters: {
    ids?: number[];
    createdBy?: string;
    name?: string;
    owner?: string;
    users?: string[];
    config?: string;
    archived?: boolean;
    page?: number;
    pageSize?: number;
  }) => Promise<PaginatedResponse<Package>>;
  get: (id: number) => Promise<Package>;
  create: (p: Partial<Package>) => Promise<Package>;
  update: (id: number, p: Partial<Package>) => Promise<Package>;
  remove: (id: number) => Promise<void>;
}

export const packages = (client: HttpClient): PackageApi => ({
  getAll: (filters: {
    ids?: number[];
    createdBy?: string;
    name?: string;
    owner?: string;
    users?: string[];
    config?: string;
    archived?: boolean;
    page?: number;
    pageSize?: number;
  }): Promise<PaginatedResponse<Package>> =>
    client.get({ url: '/package', payload: filters }),
  get: (id: number): Promise<Package> => client.get({ url: `/package/${id}` }),
  create: (p: Partial<Package>): Promise<Package> =>
    client.post({ url: '/package', payload: p }),
  update: (id: number, p: Partial<Package>): Promise<Package> =>
    client.put({
      url: `/package/${id}`,
      payload: {
        ...p,
        id,
      },
    }),
  remove: (id: number): Promise<void> =>
    client.delete({ url: `/package/${id}` }),
});
