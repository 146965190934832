import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  CoreDataListPage,
  useToggle,
  configurationSearchFields,
  CoreModal,
  useQueryString,
} from '@amzn/dots-core-ui';
import { Configuration, PackageType, useClient } from '../../api';
import ConfigurationColumnDefinitions from './ConfigurationColumnDefinitions';
import { PackageForm } from '../Packages';

type ConfigurationSearchParams = {
  name?: string;
  createdBy?: string;
  owner?: string;
  users?: string[];
  page?: number;
  pageSize?: number;
};

const ConfigurationList = ({
  title,
  packageType,
}: {
  title: string;
  packageType: PackageType;
}): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isModalOpen, closeModal, openModal] = useToggle();
  const { tmsClient } = useClient();
  const { params } = useQueryString<ConfigurationSearchParams>();

  const dataFetcher = async () => {
    const response = await tmsClient.configurations.getAll({
      name: params.name,
      packageCreatedBy: params.createdBy,
      page: params.page || 1,
      pageSize: params.pageSize,
    });

    return {
      content: response.content,
      count: response.pageSize,
      total: response.pageTotal,
    };
  };

  return (
    <>
      <CoreModal
        title={`Add a new ${packageType}`}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <PackageForm
          onCancel={closeModal}
          onSubmit={async (newPackage): Promise<void> => {
            const result = await tmsClient.packages.create({
              ...newPackage,
              type: packageType,
            });
            history.push(`${path}/${result.id}/suites`);
          }}
        />
      </CoreModal>
      <CoreDataListPage
        title={title}
        columnDefinitions={ConfigurationColumnDefinitions}
        getItemId={(item: Configuration): number => item.id}
        filters={configurationSearchFields}
        dataFetcher={dataFetcher}
        onAddButtonClick={openModal}
        onRowClick={(item: Configuration): void =>
          history.push(
            `${path}/${item.packageId}/${
              packageType === 'test' ? `suites#${item.name}` : 'versions'
            }`
          )
        }
      />
    </>
  );
};
export default ConfigurationList;
